.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gridjs-search {
  position: relative;
  float: right;
}

.center-content {
  padding-top: 100px;
  padding-bottom: 100px;
}

.timeSet {
  padding-top: 13px;
  padding-bottom: 20px;
}

.rmsc .dropdown-container .dropdown-content {
  z-index: 111;
}

.basic-single {
  z-index: 111;
}

.classgroup {
  margin-bottom: 23px;
}

.auth-page-content .nav-tabs .nav-link i {
  font-size: 30px;
  position: relative;
  top: 4px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: white;
}

#my-tooltip-1 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-2 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-3 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-11 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-4 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-5 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-6 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-7 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-8 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-9 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-10 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-login-1 {
  background-color: #ecb5b5;
  color: black;
}

#my-tooltip-login-2 {
  background-color: #ecb5b5;
  color: black;
}

.avg-call-duration.card {
  background: #fff !important;
}

.avg-unique-call.card {
  background: #fff !important;
}

.number-of-employee.card {
  background: #fff !important;
}

.missed-call.card {
  background: #fff !important;
}

.incoming-call.card {
  background: #fff !important;
}

.Total-Call-duration.card {
  background: #fff !important;
}

.not-Picked-call.card {
  background: #fff !important;
}

.input-group {
  flex-wrap: nowrap;
}

.sn-call-apply-btn-reset {
  display: flex;
}

.tbl-user-manage-ui-sn11 .sc-fqkvVR {
  text-align: center;
}

.navbar-menu .navbar-nav .nav-link.active {
  color: #fb6c6d;
  background: #fff2f2;
}

.navbar-menu .navbar-nav .nav-sm .nav-link.active {
  color: #fb6c6d;
}

.navbar-menu .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"] {
  color: #fb6c6d;
}

.navbar-menu .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  color: #fb6c6d;
}

.navbar-menu .navbar-nav .nav-sm .nav-link.active:before {
  background-color: #fb6c6d;
}

.flatpickr-weekdays {
  background-color: #343a40;
}

.flatpickr-months {
  background-color: #343a40;
}

span.flatpickr-weekday {
  background: #343a40;
}

.flatpickr-calendar.arrowTop::before {
  border-bottom-color: #343a40;
}

.flatpickr-day.today {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.n-tble-home-page .sc-gEvEer {
  background: black !important;
}

.n-tble-home-page .sc-eqUAAy {
  background: #fff2f2 !important;
  color: #333;
}

.n-tble-home-page .sc-jlZhew {
  font-weight: 600;
}

.sn-header-select-employess-home .dropdown-container:focus-within {
  border-color: #343a40;
  box-shadow: #343a40 0 0 0 1px;
}

.sn-team-managment-tble .rdt_TableHead .gocYik {
  background: #fff2f2 !important;
  color: #333;
}

.sn-team-managment-tble .rdt_TableHead .ilZlKI {
  background: #fff2f2 !important;
  color: #333;
}

.avatar-container {
  display: flex;
}

.sn-down-arrow-top {
  margin-left: 10px;
  font-size: 20px;
  color: #7e7b7bcc;
}

.sn-free-trail-title-top {
  background: #fff2f29e;
  border-radius: 2px;
  padding: 10px 22px 10px 22px;
  text-align: center;
  margin: auto;
  display: block;
}

.sn-free-trail-title-top h3 {
  margin: 0;
  font-size: 13px;
  color: #fb6c6d;
}

.sn-free-trail-title-top h3 i {
  position: relative;
  bottom: -2px;
}

.archives-recording-tble .btn-soft-danger {
  margin-left: 5px;
}

.navbar-menu .navbar-nav .nav-link:hover {
  color: #fb6c6d !important;
}

.navbar-menu .navbar-nav .nav-sm .nav-link:hover:before {
  background-color: #fb6c6d !important;
  opacity: 1;
}

.header-profile-user {
  height: 32px;
  width: 32px;
  object-fit: contain;
}

.header-profile-main-drow .user-name-text {
  font-size: 12px;
}

.header-profile-main-drow .user-name-sub-text {
  font-size: 10px !important;
}

.custom-datatable .sc-feUZmu {
  /* overflow: scroll; */
}

.salenninja-dashboard-agent-tblemani .eMkDBs {
  overflow: scroll;
  height: auto;
  min-height: 60px;
  max-height: 600px;
}

.salenninja-dashboard-agent-tblemani .cZLCxK {
  height: auto;
  overflow: scroll;
}

.sn-profile-user-top-icon li a i {
  margin-right: 11px;
}

.sn-profile-user-top-icon .nav-link.active {
  color: #fb6c6d !important;
}

.sn-profile-user-top-icon .nav-link {
  color: #282c34;
}

.sn-profile-user-top-icon .nav-link::after {
  background: #fb6c6d !important;
}

.sn-tble-call-archive-mainn .remove-list {
  margin-left: 5px;
}

.sn-dashboard-graph-leftcrcle {
  height: 510px;
}

.sn-over-call-graph-main-home .card-body {
  /* height: 350px; */
  /* height: 370px; */
}

.n-tble-home-page .bbIKyw:not(:last-of-type) {
  background: #fff6f957;
}

.n-tble-home-page .kdsIbX:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff6f957;
}

.sn-dasboard-datepicker-btn-main .bg-dark {
  background: #efdce4 !important;
  color: #f17171 !important;
  border-color: #efdce4 !important;
}

.sn-team-managment-tble .bbIKyw {
  background-color: #fff6f957 !important;
}

.sn-team-managment-tble .kdsIbX {
  background-color: #fff6f957 !important;
}

.sn-header-select-employess-home .select__control {
  background: transparent;
}

ul#navbar-nav .menu-title span {
  margin-left: 7px;
}

.ribbon-box .ribbon-primary {
  background: #f17171;
}

.n-tble-home-page h5 {
  color: #333 !important;
  font-size: 12px !important;
}

.sn-login-tabs-header .active.nav-link {
  color: #f17171 !important;
}

.sn-login-tabs-header .nav-link::after {
  background-color: #f17171 !important;
}

.sn-login-tabs-header .nav-link:hover {
  color: #f17171;
}

.classgroup .dropdown-content {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.classgroup .dropdown-content input[type="checkbox"] {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 2px;
}

.classgroup .dropdown-content input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #495057;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.classgroup .dropdown-content input[type="checkbox"]:checked {
  background-color: #fb6c6d;
  border-color: #fb6c6d;
}

.classgroup .dropdown-content input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.classgroup label {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  align-items: left;
  text-align: left;
  font-size: 12px;
}

.button-grey-main {
  background: #8080804d;
}

.button-grey-main:hover {
  background: gray;
  color: #fff;
}

.sn-employeessReport-tble-in-out .gridjs-th-content span {
  font-size: 12px;
}

.sn-employeessReport-tble-in-out .gridjs-th-content span div {
  font-size: 12px !important;
}

.sn-call-report-status-design-solve span.badge {
  padding: 7px 7px 7px 10px;
  margin-left: 7px;
  font-size: 10px;
}

.sn-call-report-status-design-solve i {
  position: relative;
  bottom: -2px;
  font-size: 16px;
  margin-left: 10px;
}

.sn-login-pageimg-bg .bg-overlay {
  background: url(http://137.59.52.100/static/media/profile-bg.d7455cbd3ea6e42f8a1b.jpg);
  width: 100%;
  object-fit: cover;
  background-position: center center;
}

.sn-login-pageimg-bg .bg-overlay {
  background: url(https://app.salesninjacrm.com/static/media/profile-bg.d7455cbd3ea6e42f8a1b.jpg);
  background-position: 50%;
  object-fit: cover;
  width: 100%;
}

.sn-team-managment-tble span.font-weight-bold {
  font-weight: 900;
}

.sn-top-inputs-heading .form-label {
  font-weight: 600;
}

.flatpickr-day.today {
  background-color: transparent !important;
}

.sn-total-calltype-btn-status span.badge {
  padding: 5px 5px 5px 5px;
  font-weight: 700;
  text-transform: capitalize !important;
}

.sn-invocie-table-datastatus span.badge {
  padding: 7px 7px 7px 7px;
  width: 80px;
  font-weight: 800;
}

.salenninja-dashboard-agent-tblemani .iLVlUV {
  overflow-y: scroll;
  height: calc(100% - 0px);
}

.sn-total-calltype-btn-status .rdt_Table {
  height: calc(100% - 0px);
}

.sn-total-calltype-btn-status .iLVlUV {
  height: calc(100% - 0px);
}

.sn-total-calltype-btn-status .rdt_Table {
  height: calc(100% - 0px);
  overflow: scroll;
}

.flatpickr-day.today {
  color: #333;
}

.flatpickr-day.today:hover {
  color: #333;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.no-data-available {
  position: relative;
  pointer-events: none;
}

.no-data-available::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  content: "Data not available";
  background-color: #fcfcfcc9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.sn-profile-image-updated {
  text-align: center;
  margin: auto;
  display: block;
  justify-content: center;
  align-items: center;
}

.sn-profile-image-updated h5 {
  color: #fff;
}

.profile-setting-img .overlay-content {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
}

.profile-wid-bg .profile-wid-img {
  height: 315px;
}

.sn-tble-call-archive-mainn h6 {
  text-align: left;
}

.sn-tble-call-archive-mainn h5 {
  text-align: left;
}

.sn-employee-managment-tble-detail span {
  text-align: left;
}

.sn-tble-bottom-scroll-main .timeSet {
  text-align: left;
}

.img-filter-no-data {
  filter: blur(2px);
}

.sn-newtblemain {
  background: #f3f3f9;
}

table.dataTable thead {
  cursor: pointer;
  background: rgb(255, 242, 242);
  font-weight: 200 !important;
}

.btn {
  box-shadow: unset;
}

.sntble-new-design th.sorting {
  position: relative;
  text-align: left;
}

.sntble-new-design {
  padding-top: 15px !important;
  padding-bottom: 0px !important;
}

.sntble-new-design .table-striped {
  margin-top: 5px;
}

.sntble-new-design .dataTables_paginate {
  justify-content: end;
  align-items: end;
  float: inline-end;
}

.sntble-new-design a.page-link.page-link {
  color: #333;
}

.sntble-new-design .page-link.active,
.active>.page-link {
  background: #fff2f2;
  border: 1px solid #e9ebec;
  color: #fb6c6d;
}

th.sorting_desc:after {
  display: none;
}

th.sorting:after {
  display: none;
}

th.sorting_desc::before {
  display: none;
}

th.sorting:before {
  display: none;
}

th.sorting_asc:before {
  display: none;
}

th.sorting_asc:after {
  display: none;
}

th.sorting_desc:after {
  content: "/F0360";
  display: contents;
}

th.sorting:after {
  content: "";
  display: contents;
}

th.sorting {
  position: relative;
}

th.sorting_asc {
  position: relative;
  text-align: left;
}

th.sorting_desc {
  position: relative;
  text-align: left;
}

th.sorting_asc:after {
  position: absolute;
  display: contents;
  transform: rotate(180deg) !important;
}

.sntble-new-design table.dataTable>thead .sorting_asc:after {
  content: "\F0360" !important;
}

.sntble-new-design table.dataTable>thead .sorting_desc:after {
  content: "\f035d" !important;
}

.sntble-new-design .custom-select {
  display: inline-block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 0 !important;
}

.dark-mode .flatpickr-day.today:hover {
  color: red;
}

.sn-invocie-table-datastatus span.badge {
  padding: 10px 10px 10px 10px;
  width: 80px;
  font-weight: 800;
  text-align: center;
  font-size: 12px;
}

.colorscheme-cardradio h4 {
  color: red;
}

.dark {
  background-color: red !important;
}

.sn-tble-call-archive-mainn .hgByPU div:first-child {
  margin-left: 0 !important;
}

.ms-sm-3.header-item.topbar-user.header-profile-main-drow.dropdown.show .sn-profile-down-hover {
  transform: rotate(180deg);
}

.sn-tble-bottom-scroll-main .gmlwXk {
  position: relative;
  width: 100%;
}

.form-check-input:checked {
  background-color: #f17171;
  border-color: #f17171;
}

.sn-avg-unique-mumber-call h4 {
  font-size: 16px;
}

.sn-button-eye-edit-user button {
  padding: 4px 4px 4px 4px;
}

.sn-total-outgoing-callbg {
  background-color: #80adfd82;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.sn-total-missed-callbg {
  background-color: #f171718a;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.sn-total-rejected-callbg {
  background-color: #eca8f796;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.sn-total-incoming-callbg {
  background-color: #67b1736b;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.sn-total-callbg {
  background-color: #dedef3f5;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.sn-total-not-picked-upby-clintng {
  background-color: #f5d17f8f;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  fill: #000000;
}

.sn-managment-team-scroll-tble {
  overflow: scroll;
}

.sn-managment-team-scroll-tble .custom-datatable .sc-feUZmu {
  overflow: scroll;
}

span.sn-managment-team-scroll-tble {
  white-space: inherit !important;
}

span.sn-managment-team-scroll-tble {
  white-space: initial;
}

:root {
  --darkmodebgblack: #1a1d21;
  --darkmodewhitetext: #d7cbcb;
  --darkmodebglight: #212529;
  --darkmodeblackborder: #393e42;
  --text-white-colr: #ffffff;
}

.sn-dark-mode {
  background: var(--darkmodebgblack);
}

.sn-dark-mode .sn-sideBar-navbar-main {
  background: var(--darkmodebglight);
}

.sn-dark-mode ul#navbar-nav li a {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .navbar-menu .navbar-nav .nav-link.active {
  color: var(--darkmodewhitetext);
  background: var(--darkmodebgblack);
}

.sn-dark-mode .navbar-header {
  background: var(--darkmodebglight);
}

body.sn-dark-mode .footer {
  background: var(--darkmodebglight);
}

.sn-dark-mode .header-item i {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .page-title-box {
  background: var(--darkmodebglight);
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .sn-down-arrow-top i {
  color: var(--darkmodebglight);
}

.sn-dark-mode .sn-top-inputs-heading label {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .card {
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  background: var(--darkmodebglight);
}

.sn-dark-mode tr.gridjs-tr td {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .sn-user-profile-update .form-label {
  margin-bottom: 0.5rem;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .bg-light {
  --vz-bg-opacity: 1;
  background-color: rgb(19 20 20) !important;
}

.sn-dark-mode .bg-light {
  --vz-bg-opacity: 1;
  background-color: rgb(19 20 20) !important;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .rmsc .dropdown-container {
  background-color: var(--darkmodebglight);
  color: var(--darkmodewhitetext);
  border: 0;
}

.sn-dark-mode .flatpickr-input[readonly] {
  cursor: pointer;
  background-color: var(--darkmodebglight);
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-call-duration.card {
  background: var(--darkmodebglight) !important;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .sn-avg-unique-mumber-call {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-unique-call.card {
  background: var(--darkmodebglight) !important;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-unique-call.card {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .number-of-employee.card {
  background: var(--darkmodebglight) !important;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .number-of-employee.card h6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .Total-Call-duration.card h6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .number-of-employee.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .Total-Call-duration.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .Total-Call-duration.card {
  background-color: var(--darkmodebglight) !important;
}

.sn-dark-mode .not-Picked-call.card {
  background-color: var(--darkmodebglight) !important;
}

.sn-dark-mode .incoming-call.card {
  background-color: var(--darkmodebglight) !important;
}

.sn-dark-mode .missed-call.card {
  background-color: var(--darkmodebglight) !important;
}

.sn-dark-mode .missed-call.card h6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .missed-call.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .incoming-call.card h6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .incoming-call.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .not-Picked-call.card H6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .not-Picked-call.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .app-search .form-control {
  background-color: var(--darkmodebgblack);
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-unique-call.card h6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-unique-call.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .apexcharts-yaxis text,
.apexcharts-xaxis text {
  fill: var(--darkmodewhitetext) !important;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .card-header {
  border: 0;
  background: var(--darkmodebglight);
}

.sn-dark-mode .card-header h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-call-duration.card h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .avg-call-duration.card h4 span {
  color: var(--darkmodewhitetext) !important;
}

.sn-dark-mode .avg-unique-call.card h4 span {
  color: var(--darkmodewhitetext) !important;
}

.sn-dark-mode .number-of-employee.card h4 span {
  color: var(--darkmodewhitetext) !important;
}

.sn-dark-mode .list-group-flush.border-dashed .list-group-item {
  border-style: dashed !important;
  background: var(--darkmodebglight);
}

.sn-dark-mode .list-group-flush.border-dashed .list-group-item h6 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .list-group-flush.border-dashed .list-group-item h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .header-profile-main-drow {
  background: var(--darkmodebglight);
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .header-profile-main-drow .user-name-text {
  font-size: 12px;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .sn-profile-down-hover i {
  color: var(--darkmodewhitetext) !important;
}

.sn-dark-mode .navbar-menu {
  border: 0;
}

.sn-dark-mode .n-tble-home-page .bbIKyw:not(:last-of-type) {
  background: #21262a;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .isyjeR:not(:last-of-type) {
  border-bottom: 1px solid var(--darkmodeblackborder);
  border: 1px solid var(--darkmodeblackborder);
  color: var(--darkmodewhitetext);
  background-color: #21262a;
}

.sn-dark-mode .kqWIVd {
  color: var(--darkmodewhitetext);
  background-color: rgb(33 38 42);
}

.sn-dark-mode .n-tble-home-page h5 {
  color: var(--darkmodewhitetext) !important;
}

.sn-dark-mode .hgByPU div:first-child {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .isyjeR {
  background-color: var(--darkmodebgblack);
  min-height: 48px;
}

.sn-dark-mode .page-title-box h4 {
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .n-tble-home-page .sc-eqUAAy {
  background: #1b212a !important;
  color: var(--darkmodewhitetext);
  border-color: var(--darkmodeblackborder);
}

.sn-dark-mode .kqWIVd button svg {
  color: var(--darkmodewhitetext) !important;
  fill: var(--darkmodewhitetext);
}

.sn-dark-mode .jEJTTw {
  border-right: 1px solid var(--darkmodeblackborder);
  border-left: 1px solid var(--darkmodeblackborder);
}

.sn-dark-mode .eoewNc {
  border-color: var(--darkmodeblackborder);
}

.sn-dark-mode .jEJTTw:first-child {
  border-color: var(--darkmodeblackborder);
}

.sn-dark-mode .list-group-flush.border-dashed .list-group-item {
  border-style: dashed !important;
  background: var(--darkmodebglight);
  border-color: var(--darkmodeblackborder);
}

img.darkthemelogo {
  display: none;
}

.sn-dark-mode img.lightthemelogo {
  display: none;
}

.sn-dark-mode img.darkthemelogo {
  display: block !important;
  margin-top: 20px;
}

.sn-dark-mode .n-tble-home-page .kdsIbX:not(:last-of-type) {
  background: #000;
  border-color: var(--darkmodeblackborder);
}

.sn-dark-mode .huFvJk {
  background: var(--darkmodebglight);
}

.sn-dark-mode .n-tble-home-page .kdsIbX:not(:last-of-type) {
  background: var(--darkmodebglight);
  border-color: 1px solid --darkmodeblackborder;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .cUBTAa {
  background-color: rgb(33 37 41);
  min-height: 48px;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .cUBTAa:not(:last-of-type) {
  border-bottom: 1px solid --darkmodeblackborder;
}

.sn-dark-mode .hdhBiQ {
  border-right: 1px solid --darkmodeblackborder;
  border-left: 1px solid --darkmodeblackborder;
}

.sn-dark-mode nav.sc-eeDRCY.fceIjj.rdt_Pagination {
  color: var(--darkmodewhitetext);
  background-color: rgb(33 38 42);
}

.sn-dark-mode .gRhxQD {
  color: var(--darkmodewhitetext);
  fill: var(--darkmodewhitetext);
}

.sn-dark-mode .gRhxQD:disabled {
  cursor: unset;
  color: var(--darkmodewhitetext);
  fill: var(--darkmodewhitetext);
}

.sn-dark-mode .cUBTAa:not(:last-of-type) {
  border-bottom: 1px solid --darkmodeblackborder;
}

.sn-dark-mode .hdhBiQ {
  border-right: 1px solid --darkmodeblackborder;
  border-left: 1px solid --darkmodeblackborder;
}

.sn-dark-mode .n-tble-home-page .kdsIbX:not(:last-of-type) {
  background: var(--darkmodebglight);
  border-bottom: 1px solid --darkmodeblackborder;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .dzoGwM {
  border-right: 1px solid --darkmodeblackborder;
  border-left: 1px solid --darkmodeblackborder;
  border-bottom: 1px solid --darkmodeblackborder;
}

.sn-dark-mode .hdhBiQ:first-child {
  border-left: 1px solid --darkmodeblackborder;
}

.sn-profile-avatar-pic {
  border: 1px solid #e76969;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  line-height: 25px;
  background: #e0b2b2;
  color: var(--darkmodewhitetext);
  position: absolute;
  top: 24px;
  margin-left: -15px;
}

.sn-profile-main-user-user .avatar-lg {
  text-align: center;
  line-height: 90px;
  font-size: 40px;
  background: #06b6d4;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode .form-control {
  background-color: var(--darkmodebgblack);
  border: 0px solid;
}

.sn-dark-mode .sn-team-managment-tble .bbIKyw {
  background-color: #000 !important;
  color: var(--darkmodewhitetext);
}

.sn-dark-mode ul#navbar-nav {
  margin-top: 13px;
}

.sn-out-going-call {
  background: #80adfd24;
  color: #558ced !important;
  margin-left: 7px;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
}

.sn-out-going-text {
  color: #558ced;
  position: relative;
  bottom: -2px;
  font-size: 16px;
  margin-left: 0px;
}

.sn-out-incoming-call {
  background: #d1ebd240;
  color: #67b173;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
  margin-left: 7px;
}

.sn-out-incoming-text {
  color: #208735;
  position: relative;
  bottom: -2px;
  font-size: 16px;
}

.sn-out-missed-call {
  background: #f7c1bd2e;
  color: Red;
  margin-left: 7px;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
}

.sn-out-missed-text {
  color: Red;
  position: relative;
  bottom: -2px;
  font-size: 16px;
}

.sn-reject-call-icontext {
  color: #eca8f7;
  position: relative;
  bottom: -2px;
  font-size: 16px;
  margin-right: 7px;
}

.sn-out-picked-call {
  background: #9a9ad514;
  color: blue;
}

.sn-out-attended-call {
  background: #9a9ad514;
  color: blue;
}

.sn-out-rejected-call {
  background: #eca8f71c;
  color: #db6ced;
  margin-left: 7px;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
}

.sn-out-rejected-text {
  color: #db6ced;
  position: relative;
  bottom: -2px;
  font-size: 16px;
  margin-left: 10px;
}

.sn-delete-text-tble {
  background: #f7c1bd2e;
  color: Red;
  margin-left: 7px;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
}

.sn-pending-text-tble {
  color: #29badb;
  background: #29badb0d;
  margin-left: 0px;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
}

.sn-archived-text-tble {
  color: #67b173;
  background: #4caf5014;
  font-size: 11px;
  padding: 7px 7px 7px 7px;
  font-weight: 700;
  border-radius: 3px;
}

.snemployee-report-total-c {
  background: #a1d3fa4a !important;
  border-radius: 14px;
  display: block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 50px;
}

.sn-dark-mode .avg-call-duration h6 {
  color: var(--darkmodewhitetext) !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: var(--text-white-colr);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.sn-dark-mode .apexcharts-yaxis text,
.apexcharts-xaxis text {
  fill: #333333 !important;
}

.sn-dark-mode .apexcharts-yaxis text,
.apexcharts-xaxis text {
  fill: var(--darkmodewhitetext);
}

.sn-dark-mode .apexcharts-text tspan {
  font-family: inherit;
  fill: var(--darkmodewhitetext);
}

.sn-admin-login-logo {
  text-align: center;
  margin-bottom: 40px;
}

.sn-footer-botom-page footer {
  background: var(--text-white-colr) !important;
  left: 0;
  right: 0;
  width: 100%;
}

.sn-notification-icon {
  display: flex;
}

.sn-pricing-box-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sn-heading-pera h6 {
  text-align: left;
  padding-left: 23px;
}

.snheader-main-modal p {
  margin: 0;
}

.snheader-main-modal {
  display: flex;
  justify-content: space-between;
}

.text-center.sn-plan-summery-tble-sub {
  background: #fff;
}

.subscription-expired-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}

.sn-expired-section img {
  width: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.sn-expierd-perragraph p {
  color: #686262;
  margin: 0;
  margin-bottom: 40px;
}

.sn-expired-section {
  text-align: center;
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding-bottom: 40px;
  background-color: #fff;
  border-radius: 4px;
}

.sn-date-sub-btn-main {
  width: 100%;
  max-width: 170px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.sn-select-sidebr span {
  font-size: 18px;
  line-height: 30px;
}

.sn-select-sidebr {
  padding-left: 40px;
}

.sn-sidebar-btn-next {
  margin-top: 50px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.tooltip-container .tooltip-text {
  width: 280px;
  background-color: #333;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  position: absolute;
  z-index: 1050;
  bottom: 18%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

th:has(> #uniqu:hover) {
  z-index: 111;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 53%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.btn-tooltip i {
  color: #fda403;
}

.tooltip-text p {
  text-wrap: wrap;
  padding: 12px 10px;
  margin: 0;
  font-weight: 400;
}

.with-tooltip th.gridjs-th .gridjs-th-content,
.with-tooltip .gridjs-container,
.with-tooltip .gridjs-wrapper {
  overflow: inherit;
}

th:hover {
  z-index: 111;
}

.sn-employeessReport-tble-in-out th.gridjs-th .gridjs-th-content {
  overflow: visible;
}

.sn-employeessReport-tble-in-out.with-tooltip .gridjs-wrapper {
  overflow-y: scroll !important;
}

.sn-employeessReport-tble-in-out.with-tooltip table.gridjs-table {
  table-layout: auto;
  overflow: hidden;
}

.sn-employeessReport-tble-in-out.with-tooltip .gridjs-wrapper {
  overflow: auto !important;
}

.hovertooltip {
  top: 0px;
  color: red;
}

.tooltipsalesninja {
  top: -30px;
  background-color: black;
  color: white;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  width: 200px;
  height: 200px;
}

.hovertooltip:hover .tooltipsalesninja {
  opacity: 1;
}

.salenninja-note-title h6 {
  margin-top: 30px;
}

.salen-ninja-select-lble {
  text-align: left;
  margin: auto;
  display: block;
  margin-top: 4px;
  font-size: 10px;
}

.sales-ninja-select-maltipale-lable {
  margin-top: 0px;
  font-size: 13px;
}

.salenninja-note-title h5 {
  font-size: 14px;
}

.salenninja-note-title {
  background: transparent;
  color: #333;
  margin: auto;
  text-align: left;
}

.salen-ninja-select-lble span {
  color: #fb0707;
  margin-right: 5px;
}

.salenninja-note-title h5 span {
  color: #f17171;
}

.salen-ninja-help-support-btn i {
  font-size: 22px;
  font-weight: 500;
  color: #f17171;
}

.sn-tble-droup-down-edit-delete .rdt_Table {
  overflow: visible;
}

.sn-dashboard-blur-img::after {
  content: "Sample Data";
  position: absolute;
  left: 0;
  right: 0;
  border: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  display: inline-table;
  font-size: 20px;
  font-weight: 500;
}

.sn-employeessReport-tble-in-out .gridjs-wrapper {}

.sn-employeessReport-tble-in-out .gridjs-wrapper {
  overflow: inherit !important;
}

.sn-employeessReport-tble-in-out .gridjs-container {
  overflow: inherit !important;
}

.sales-ninja-header-loader {}

.sn-header-select-employess-home.sn-top-inputs-heading span div {}

.sn-call-report-duration-txt {
  position: absolute;
  left: 15px;
  bottom: 10px;
}

.salenninja-dashboard-agent-tblemani .iLVlUV {
  height: 100%;
  overflow-y: scroll;
  /* min-height: 600px; */
  min-height: auto;
  max-height: 730px;
}

.sn-employee-report-tble-respose tr th {
  width: 250px;
}

.sn-employee-report-tble-respose tr td {
  width: 250px;
}

.sn-employee-report-tble-respose {
  overflow: scroll;
}

.sn-employee-report-tble-respose td.gridjs-td {
  width: 390px;
}

.sn-call-report-status-design-solve .hgByPU div:first-child {
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}

@media (min-width: 320px) and (max-width: 767px) {
  .sn-tble-call-archive-mainn .hgByPU div:first-child {
    margin-left: 0 !important;
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    text-align: left;
  }

  .sn-total-calltype-btn-status span.badge {
    margin-top: 5px;
  }

  .sn-managment-team-scroll-tble .krVTia div:first-child {
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  .salen-ninja-select-lble {
    text-align: center;
  }

  .sn-employeessReport-tble-in-out .gridjs-wrapper {}

  th:hover {
    z-index: 111;
  }

  .sn-header-select-employess-home.sn-top-inputs-heading span div {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span div {
    font-size: 10px !important;
  }

  .n-tble-home-page h5 {
    font-size: 11px !important;
    padding-right: 0 !important;
  }

  .sn-managment-team-scroll-tble .form-switch-success {
    margin-left: 0 !important;
    font-size: 12px;
  }

  .sn-refresh-btn.btn {
    display: none !important;
  }

  .sn-dasboard-datepicker-btn-main {
    margin-bottom: 20px;
  }

  .page-content {
    padding: 0;
  }

  .sn-top-inputs-heading {
    margin-top: 40px !important;
  }

  .sn-team-managment-main-page {
    margin-top: 40px;
  }

  .sn-employees-managment {
    margin-top: 40px;
  }

  .sn-employees-managment .sn-tble-droup-down-edit-delete .rdt_Table {
    overflow: scroll;
  }

  .custom-datatable .sc-feUZmu {
    overflow: scroll;
  }

  .sn-role-managment-page {
    margin-top: 40px;
  }

  .sn-recording-report-page {
    margin-top: 40px;
  }

  .sn-recording-archives-page {
    margin-top: 40px;
  }

  .sn-call-report-page-top {
    margin-top: 40px;
  }

  .sn-employee-report-page-main {
    margin-top: 40px;
  }

  span.sn-role-managment-responsive-btn {
    display: flex;
  }
}

.page-title-box {
  margin: 0;
}

.sn-plan-summary-main-page .ribbon-box {
  overflow: scroll;
}

.sn-profile-page-main {}

.sn-profile-page-main .sn-user-profile-update {
  margin-bottom: 100px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .sn-tble-call-archive-mainn .hgByPU div:first-child {
    margin-left: 0 !important;
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    text-align: left;
  }

  .sn-total-calltype-btn-status span.badge {
    margin-top: 5px;
  }

  .sn-managment-team-scroll-tble .krVTia div:first-child {
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  .salen-ninja-select-lble {
    text-align: center;
  }

  .sn-employeessReport-tble-in-out .gridjs-wrapper {}

  th:hover {
    z-index: 111;
  }

  .sn-header-select-employess-home.sn-top-inputs-heading span div {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span div {
    font-size: 10px !important;
  }

  .n-tble-home-page h5 {
    font-size: 11px !important;
    padding-right: 0 !important;
  }

  .sn-managment-team-scroll-tble .form-switch-success {
    margin-left: 0 !important;
    font-size: 12px;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .sn-profile-name-main-sn {
    display: block !important;
  }
}

.btn-forgot-password {
  background: transparent;
  border: 0;
}

.btn-forgot-password:hover {
  background: transparent;
}

.createDateRangePicker .ant-picker-header .ant-picker-header-super-prev-btn {
  color: white;
}

.createDateRangePicker .ant-picker-header .ant-picker-header-prev-btn {
  color: white;
}

.createDateRangePicker .ant-picker-header .ant-picker-header-next-btn {
  color: white;
}

.createDateRangePicker .ant-picker-header .ant-picker-header-super-next-btn {
  color: white;
}

.createDateRangePicker .ant-picker-header {
  background-color: #343a40 !important;
  color: #ffffff !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #1890ff !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #1890ff !important;
}

.ant-picker .ant-picker-suffix {
  color: #f17171;
}

.sn-call-type-newer-call i {}

.rejected-icon-never {
  position: relative;
  top: 4px;
}

.sn-out-missed-text {}

.page-content {
  margin-bottom: 40px;
}

.ikexHN {
  min-width: 200px;
}

.sn-login-pageimg-bg li.nav-item .active {
  background: #f17171;
  transition: 0.15s;
  color: #fff !important;
}

.sn-login-pageimg-bg .nav-link::after {
  background: #f17171 !important;
}

.sn-login-pageimg-bg .card-body {
  padding: 0px 0px 40px 0px;
}

.ebodjl {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.sn-sales-ninja-share-btn a {
  background: #f17171;
  padding: 6px 10px 6px 10px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  animation: mymoveheader 2s infinite;
}

@keyframes mymoveheader {
  from {
    background-color: red;
  }

  to {
    background-color: #fb6c6d;
  }
}

.sn-sales-ninja-share-btn {
  margin-right: 20px;
}

.sn-sales-ninja-share-btn a i {
  margin-right: 5px;
}

.sales-ninja-close-modal-btn button {
  background: #fb6c6ddb;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 0;
  color: #fff;
  line-height: 22px;
}

.sales-ninja-modal-body-main {}

.salen-ninak-social-media-list ul li {
  list-style-type: none;
  margin-right: 15px;
}

.salen-ninak-social-media-list ul {
  display: flex;
  padding: 0;
}

.salen-ninak-social-media-list ul li a {}

.salen-ninak-social-media-list ul li a:hover {}

.salen-ninak-social-media-list {
  margin-top: 0px;
  padding-top: 0;
}

.sales-ninja-close-modal-btn {
  background: #fff2f2;
  padding: 13px 15px 12px 20px !important;
}

.sales-ninja-close-modal-btn h5 {
  color: #333;
}

.sales-ninja-copyt-url-btn input {
  border: 1px solid #ccc;
  border-radius: 4px 0px 0px 4px;
  width: 100%;
}

.sales-ninja-copyt-url-btn button {
  background: #fb6c6d;
  border: 1px solid #fb6c6d;
  border-radius: 0px 4px 4px 0px;
  color: #fff;
  position: absolute;
  right: 20px;
}

.sales-body-modal-main .modal-content {
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.sales-ninja-modal-body-main {
  padding-bottom: 5px;
}

.sales-ninja-copyt-url-btn {
  margin-bottom: 15px;
}

.sales-ninja-modal-share-txt p strong {
  font-weight: 700;
}

@media (min-width: 320px) and (max-width: 767px) {
  .sn-tble-call-archive-mainn .hgByPU div:first-child {
    margin-left: 0 !important;
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    text-align: center;
  }

  .sn-total-calltype-btn-status span.badge {
    margin-top: 5px;
  }

  .sn-managment-team-scroll-tble .krVTia div:first-child {
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  .salen-ninja-select-lble {
    text-align: center;
  }

  th:hover {
    z-index: 111;
  }

  .sn-header-select-employess-home.sn-top-inputs-heading span div {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span div {
    font-size: 10px !important;
  }

  .n-tble-home-page h5 {
    font-size: 11px !important;
    padding-right: 0 !important;
  }

  .sn-managment-team-scroll-tble .form-switch-success {
    margin-left: 0 !important;
    font-size: 12px;
  }

  .sn-refresh-btn.btn {
    display: none !important;
  }

  .sn-dasboard-datepicker-btn-main {
    margin-bottom: 20px;
  }

  .page-content {
    padding: 0;
  }

  .sn-top-inputs-heading {
    margin-top: 40px !important;
  }

  .sn-team-managment-main-page {
    margin-top: 40px;
  }

  .sn-employees-managment {
    margin-top: 40px;
  }

  .sn-employees-managment .sn-tble-droup-down-edit-delete .rdt_Table {
    overflow: scroll;
  }

  .custom-datatable .sc-feUZmu {
    overflow: scroll;
  }

  .sn-role-managment-page {
    margin-top: 40px;
  }

  .sn-recording-report-page {
    margin-top: 40px;
  }

  .sn-recording-archives-page {
    margin-top: 40px;
  }

  .sn-call-report-page-top {
    margin-top: 40px;
  }

  .sn-employee-report-page-main {
    margin-top: 40px;
  }

  .sn-employee-report-page-main .sn-employeessReport-tble-in-out .gridjs-wrapper {
    overflow: scroll !important;
  }

  .sn-plan-summary-main-page .ribbon-box {
    overflow: scroll;
  }

  .sn-profile-page-main {
    margin-top: 100px;
  }

  .sn-profile-page-main .sn-user-profile-update {
    margin-bottom: 100px;
  }

  .sm.sn-over-call-graph-main-home {
    display: none;
  }

  .page-title-box {
    margin: 70px !important;
    margin: 0 !important;
  }

  .sn-title-page-main-head {
    margin-top: 70px;
    padding: 0 !important;
  }

  .sn-tble-call-archive-mainn .remove-list {
    margin-top: 10px;
    margin-left: 1px;
  }

  div#cell-9-undefined {
    padding-left: 5px;
  }

  .sn-tble-call-archive-mainn .hgByPU div:first-child i {
    margin-bottom: 10px !important;
    display: block;
  }

  .sn-employessreport-alert-mainnn table.gridjs-table {
    table-layout: inherit;
  }

  .sn-employee-managment-tble-detail span {
    text-align: left;
    padding: 3px 3px 3px 2px;
    margin-top: 10px;
  }

  .sn-role-managment-responsive-btn .remove-list {
    padding: 4px 4px 4px 4px;
  }

  .sn-role-managment-responsive-btn .edit-list {
    padding: 4px 4px 4px 4px;
  }

  .sn-role-managment-responsive-btn button i {
    margin-bottom: 0;
  }

  .sn-tble-call-archive-mainn div:first-child {
    margin-left: 0 !important;
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .sn-tble-call-archive-mainn .hgByPU div:first-child {
    margin-left: 0 !important;
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    text-align: left;
  }

  .sn-total-calltype-btn-status span.badge {
    margin-top: 5px;
  }

  .sn-managment-team-scroll-tble .krVTia div:first-child {
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  .salen-ninja-select-lble {
    text-align: center;
  }

  .sn-employeessReport-tble-in-out .gridjs-wrapper {}

  th:hover {
    z-index: 111;
  }

  .sn-header-select-employess-home.sn-top-inputs-heading span div {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span {
    font-size: 10px;
  }

  .sn-employeessReport-tble-in-out .gridjs-th-content span div {
    font-size: 10px !important;
  }

  .n-tble-home-page h5 {
    font-size: 11px !important;
    padding-right: 0 !important;
  }

  .sn-managment-team-scroll-tble .form-switch-success {
    margin-left: 0 !important;
    font-size: 12px;
  }

  .sn-dasboard-datepicker-btn-main {
    margin-bottom: 20px;
  }

  .page-content {
    padding: 0;
  }

  .sn-top-inputs-heading {
    margin-top: 50px !important;
  }

  .sn-team-managment-main-page {
    margin-top: 90px;
  }

  .sn-employees-managment {
    margin-top: 90px;
  }

  .sn-employees-managment .sn-tble-droup-down-edit-delete .rdt_Table {
    overflow: scroll;
  }

  .custom-datatable .sc-feUZmu {
    overflow: scroll;
  }

  .sn-role-managment-page {
    margin-top: 90px;
  }

  .sn-recording-report-page {
    margin-top: 90px;
  }

  .sn-recording-archives-page {
    margin-top: 90px;
  }

  .sn-call-report-page-top {
    margin-top: 90px;
  }

  .sn-employee-report-page-main {
    margin-top: 90px;
  }

  .sn-employee-report-page-main .sn-employeessReport-tble-in-out .gridjs-wrapper {}

  .sn-plan-summary-main-page .ribbon-box {
    overflow: scroll;
  }

  .sn-profile-page-main {
    margin-top: 100px;
  }

  .sn-profile-page-main .sn-user-profile-update {
    margin-bottom: 100px;
  }

  .sn-top-inputs-heading .form-label {
    font-size: 12px;
  }

  .sn-call-apply-btn-reset button {
    font-size: 11px;
  }

  .sn-role-managment-page .jEJTTw {}

  .sn-role-managment-page .sn-tble-call-archive-mainn .remove-list {
    margin-left: 5px;
    padding: 2px 6px 2px 6px;
  }

  .sn-role-managment-page .sn-tble-call-archive-mainn .btn-soft-success {
    padding: 2px 6px 2px 6px;
  }

  .sn-role-managment-page .sn-tble-call-archive-mainn .btn-soft-info {
    padding: 2px 6px 2px 6px;
  }

  .sn-recording-report-page .sn-header-select-employess-home.sn-top-inputs-heading .col-md-2 {
    flex: 0 0 auto;
    width: 50%;
  }

  .sn-recording-report-page .sn-header-select-employess-home.sn-top-inputs-heading .col-md-3 {
    flex: 0 0 auto;
    width: 50%;
  }

  .sn-recording-archives-page .sn-header-select-employess-home.sn-top-inputs-heading .col-md-2 {
    flex: 0 0 auto;
    width: 50%;
  }

  .sn-recording-archives-page .sn-header-select-employess-home.sn-top-inputs-heading .col-md-3 {
    flex: 0 0 auto;
    width: 50%;
  }

  .sn-dashboard-page-home .col-md-3 {
    width: 50%;
  }

  .sn-client-report-main-page {
    margin-top: 90px;
  }

  .sm.sn-over-call-graph-main-home {}

  .page-title-box {
    margin: 0 !important;
  }

  .sn-tble-call-archive-mainn .remove-list {
    margin-top: 0px;
    margin-left: 1px;
  }

  .sn-title-page-main-head {
    margin-top: 70px;
    padding: 0;
  }

  .sn-managment-team-scroll-tble div#cell-9-undefined {
    padding-left: 5px;
  }

  .sn-button-eye-edit-user .remove-list {
    margin-top: 0;
  }

  .sn-header-select-employess-home.sn-top-inputs-heading span div {}

  .sn-employessreport-alert-mainnn {
    overflow-y: scroll !important;
    width: 100%;
    max-width: 100%;
    display: inline-block;
  }

  .sn-employessreport-alert-mainnn tr th {
    width: 100px !important;
    min-width: 100px;
    max-width: 100px;
  }

  .employee-managment-table-responsive .ikexHN {
    min-width: 150px;
  }

  .sn-tble-call-archive-mainn div:first-child {
    margin-left: 0 !important;
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  table.gridjs-table {
    table-layout: unset;
  }
}

@media (min-width: 1281px) and (max-width: 1600px) {
  .hgByPU div:first-child {
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  .sn-tble-call-archive-mainn .hgByPU div:first-child {
    margin-left: 0 !important;
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }

  .sn-managment-team-scroll-tble div#cell-9-undefined {
    padding-left: 5px;
  }

  .sn-button-eye-edit-user .remove-list {
    margin-top: 0;
  }

  .sn-profile-page-main {
    margin-top: 0px;
  }

  .sn-header-select-employess-home.sn-top-inputs-heading span div {}

  .sn-employessreport-alert-mainnn table.gridjs-table {
    overflow-y: scroll !important;
    width: 100%;
    max-width: 100%;
    display: inline-block;
  }

  .sn-employessreport-alert-mainnn tr th {
    width: 100px !important;
    min-width: 100px;
    max-width: 100px;
  }

  .employee-managment-table-responsive .ikexHN {
    min-width: 150px;
  }

  .employe-report-not-pickup-colum-tbl.col-lg-12 .gridjs-tr th {
    width: 300px !important;
    max-width: 3000px !important;
  }

  .sn-tble-call-archive-mainn div:first-child {
    /* margin-left: 0 !important;
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important; */
  }

  table.gridjs-table {
    table-layout: unset;
  }
}





.swiper.swiper-initialized.swiper-horizontal.cryptoSlider.swiper-backface-hidden:hover {
  transition-duration: hidden !important;
  transform: hidden !important;
}

.swiper.swiper-initialized.swiper-horizontal.cryptoSlider:hover {
  transform: inherit !important;
  transition-duration: inherit !important;
  overflow: hidden !important;
}

.cryptoSlider {
  cursor: default;
}

.cryptoSlider .swiper-slide {
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
}

.cryptoSlider .swiper-slide:hover {
  background-color: inherit;
}

.srno-column {
  width: 30px;
  text-align: center;
}

.dashloader {
  margin-top: 200px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.279);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.blurred {
  opacity: 0.9;
  pointer-events: none;
}

.container {
  position: relative;
  min-height: 100vh;
}

.dashboard-tooltip-eyes {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #e5f9f8;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  color: #16cbbd;
  border-bottom-left-radius: 50%;
}

.dashboard-tooltip-eyestwo {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #fef0f9;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  color: #f34ab6;
  border-bottom-left-radius: 50%;
}

.dashboard-tooltip-eyesthree {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #f0f8fc;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  color: #068dc6;
  border-bottom-left-radius: 50%;
}

.sn-dashboard-tble-toll {
  color: #ff9800;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.swiper-wrapper {
  text-transform: uppercase;
}

.sn-total-notattemped-callbg {
  background-color: #4cd5e170;
  width: 50px;
  display: block;
  text-align: center !important;
  border-radius: 14px;
  height: 20px;
  line-height: 20px;
}

.tooltip {
  border: none !important;
  z-index: 1050 !important;
}

.sn-tble-profile-dp .rounded-circle {
  width: 40px;
  height: 40px;
  line-height: 30px;
  text-align: center;
  margin: auto;
  margin-right: 10px;
  color: #fff;
}

.sm.sn-over-call-graph-main-home canvas {
  width: 100% !important;
}

.dashboard-tooltip-eyestwo {
  width: 40px;
  height: 40px;
  text-align: center;
  background: rgb(38 92 46 / 11%);
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  color: rgb(38, 92, 46);
  border-bottom-left-radius: 50%;
}

.sn-total-total-conected {
  border-radius: 14px;
  display: block;
  height: 20px;
  line-height: 20px;
  text-align: center !important;
  width: 50px;
  background: #74a4bcb5;
}

.sn-left-side-menu-bar-main-page {
  position: fixed !important;
}

.sn-winlist-employees ul li img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.sn-winlist-employees ul {
  display: flex;
}

.sn-winlist-employees ul li h4 {
  font-size: 13px;
  margin-top: 14px;
  font-weight: 700;
}

.sn-winlist-employees ul li h3 {
  font-size: 16px;
  font-weight: 600;
}

.sn-winlist-employees ul li {
  list-style-type: none;
  margin-bottom: 20px;
}

.sn-winlist-employees ul {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.sn-winlist-employees ul li:first-child img {
  border: 4px solid #ffc107;
}

.sn-winlist-employees ul li:nth-child(2) img {
  border: 4px solid #fb716d;
}

.sn-winlist-employees ul li:nth-child(3) img {
  border: 4px solid #24a739;
}

.sn-winlist-employees ul li:first-child h6 {
  background: #ffc107;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: auto;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  bottom: 12px;
}

.sn-winlist-employees ul li:nth-child(2) h6 {
  background: #fb716d;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: auto;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  bottom: 12px;
}

.sn-winlist-employees ul li:nth-child(3) h6 {
  background: #24a739;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: auto;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  bottom: 12px;
}

.sn-winlist-employees ul li h6 {
  position: relative;
  margin-top: -10px;
}

.win-people-headng {
  padding: 10px 20px 0px 20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.win-people-headng h5 {
  margin: 0;
}

.sn-star-empolyee-awardimg {
  width: 30px !important;
  height: 30px !important;
  border: 0 !important;
  border-radius: 0 !important;
  margin-top: 10px;
}

.total-connected-call-first-people img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.userstatus-onoff {
  width: 12px;
  height: 12px;
  object-fit: contain;
  position: relative;
  bottom: -1px;
  margin-right: 10px;
  position: absolute;
  right: 5px;
}

.profile-lastseen {
  font-size: 10px;
  font-weight: 600;
  color: #5d5757;
}

.agent-user-tooltip-main-box {
  display: flex;
  width: 50px;
  justify-content: space-between;
}

.agent-tooltip-tble-i {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.agent-user-tooltip-main-box {
  position: absolute;
  bottom: 30px;
}

.salenninja-dashboard-agent-tblemani .kfjYiJ {
  max-height: 645px !important;
}

.agentTable-salesninja-home .cZLCxK {
  max-height: 645px !important;
}

.salenninja-dashboard-agent-tblemani .cZLCxK {
  max-height: 645px !important;
}

.agent-main-page-tble-sec {
  display: none;
  margin-top: -40px;
}

.agentTable-salesninja-home .cZLCxK,
.salenninja-dashboard-agent-tblemani .kfjYiJ {
  max-height: 645px !important;
}

.agentmain-page-tble-tooltip {
  display: flex;
  width: 60px;
  justify-content: space-between;
}

.agent-main-page-tble-sec input {
  float: right;
  margin-top: -40px;
}

.custom-button {
  background-color: #007bff;
  color: white;
  border: none;
}

.sn-sales-ninja-share-btn a:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: 0.5s;
}

.sales-ninja-offline-user {}

.tble-dashboard-export-div {
  display: flex;
}

.sales-ninja-tble-export-btn button {
  background: transparent;
}

.sales-ninja-tble-export-btn .tble-export1 {
  color: #80a9f3;
  font-size: 19px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02) !important;
  padding: 0;
}

.sales-ninja-tble-export-btn .tble-export2 {
  color: #dc66ee;
  padding: 0;
  font-size: 19px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02) !important;
  margin-right: 10px;
  margin-left: 7px;
}

.sn-dashboard-count-btn-view {
  position: absolute;
}

.sn-profile-select-country-cd {
  height: 38px;
}

.sn-profile-header-back-btn {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
}

.sn-backbtn a i {
  font-size: 18px;
}

.profile-header-sn-tb {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.sn-app-version-page-bk {
  display: flex;
  position: inherit;
}


.sn-dasboard-datepicker-btn-main .ant-picker-range {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 38px;
  margin-top: 5px;
}

.sn-filter-show-btn-top {
  height: 37px;
  margin-top: 5px;
}

.tble-reactive-btn-tble {
  margin-top: 20px;
}

.sn-top-cannected-employee .card-body p {
  font-size: 14px;
  text-transform: capitalize !important;
}

.sn-top-cannected-employee .card-body h4 span {
  color: #333;
  font-size: 14px;
}

.sn-top-cannected-employee .card-body h4 {
  font-size: 12px !important;
}

.sn-top-cannected-employee .card-body h5 {
  font-size: 12px !important;
  float: left;
  width: 50%;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: pre-line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.sn-top-cannected-employee .card-body h5 span {
  font-size: 4px;
}

.sn-call-report-page-top .rdt_Table {
  min-width: 60px;
  max-height: 600px;
  overflow: scroll;
}

.call-logs-report-export-exel {
  position: absolute;
  top: 20px;
}

.call-logs-report-export-exel button:hover {
  background: transparent;
}

.call-logs-report-export-exel .tble-export1:hover {
  background: transparent !important;
  color: #80a9f3 !important;
}

.call-logs-report-export-exel .tble-export2:hover {
  background: transparent !important;
  color: #dc66ee !important;
}

.sn-top-caller-btn {
  color: #fb6c75;
  font-size: 18px;
}

.table-scroll .kdgCYE {
  display: block;
  flex-direction: column;
  min-height: 100px;
  max-height: 500px;
}

.tble-reactive-btn-tble button {
  background: #f17171;
  color: #fff;
}

.sn-metrics-tble-informtion span {
  color: #ff9800;
}

.sn-metrics-tble-informtion {
  margin-left: 15px;
  position: relative;
  top: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}

.sn-metrics-tble-informtion span {
  color: #fb6768;
  position: relative;
  top: 0px;
  font-size: 20px;
}

.sn-profile-intrigrate-toggle input {
  width: 50px !important;
  height: 25px;
}

.sn-profile-intrigrate-toggle .form-check-input:checked {
  background-color: #28a745;
  border-color: #28a745;
}

.sn-top-cannected-employee h5 svg {
  font-size: 14px !important;
  margin-right: 6px;
}

.sn-star-empolyee-awardimg-star {
  width: 30px !important;
  height: 30px !important;
  text-align: right;
  margin-right: 15px;
  margin-top: -8px;
}

.sn-employess-start-month {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sn-strategy-txt-main {
  font-size: 13px !important;
}

.sn-top-performance-card-new-design .top-caller-title-top {
  border-bottom: 1px solid #cccccc3d;
  padding-bottom: 7px;
  text-transform: capitalize !important;
}

.sn-top-performance-card-new-design .sn-top-performancename-photo {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  /* border-bottom: 1px solid #cccccc3d; */
}

.sn-top-performance-card-new-design .sn-performance-name-call-total h5 {
  font-size: 14px;
}

.sn-top-performance-card-new-design .sn-performance-name-call-total h4 {
  font-size: 14px;
}

.sn-performance-team-count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cccccc3d;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.sn-performance-team-count h5:first-child::after {
  content: "";
  width: 1px;
  height: 20px;
  background: #cccccc50;
  position: absolute;
  left: 50%;
  right: 0;
}

.sn-top-cannected-employee .card-body h5 i {
  position: relative;
  top: 1px;
  color: #FFC107;
}

.sn-top-cannected-employee .card-body h5:last-child {
  margin-left: 15px;
}

.selected-value {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer
}

.select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10
}

.search-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc
}



.list-country-code {
  padding: 8px;
  cursor: pointer;
  border: 1px solid #f0f0f0;
  border-top: 0;
}

.all-coutry-code {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto
}

.custom-select-container {
  font-family: Arial, sans-serif;
}

.dropdown ul li:hover {
  background-color: #f0f0f0;
}



.list-country-code.focused {
  background-color: #f0f0f0;
}

.list-country-code {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-country-code:hover {
  background-color: #f0f0f0;
}

.list-country-code.selected {
  background-color: #d1ecf1;
  color: #0c5460;
  font-weight: bold;
}

.custom-select-container {
  position: relative;
  /* width: 200px; */
}

.selected-value {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.all-coutry-code {
  list-style: none;
  margin: 0;
  padding: 0;
}

.all-coutry-code li {
  padding: 8px;
  cursor: pointer;
}

.search-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}

.sn-sales-ninja-mobile-custom-input-sn {
  width: 25%;
}
.input-custom-country-number-sn {
  width: 72%;
}
.sn-sales-ninja-country-input-mm {
  display: flex;
  justify-content: space-between;
}



.sm-custom-country-code-main input {
  width: 100% !important;
}
.select-country-code-main-register .sn-profile-select-country-cd {
  position: relative;
  top: 29px;
  border: 0 !important;
  background: #9e9e9e29;
}
.select-country-code-main-register .sn-profile-select-country-cd {
  position: absolute;
  top: 29px;
  border: 0 !important;
  background: #9e9e9e29;
  width: 100%;
  max-width: 90px;
  min-width: 100px !important;
  /* z-index: 1050; */
  background-repeat: no-repeat;
  padding: 11px 0px 10px 14px;
}
.sn-rgister-input-mobile {
  padding-left: 110px;
}
.select-country-code-main-register .sn-profile-select-country-cd:after {
  content: "";
  width: 15px;
  height: 15px;
  background: url('../src/assets/images/down-arrow.png') no-repeat center center;
  background-size: contain;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  padding-top: 11px;
  background-repeat: no-repeat;
}
.form-select.sn-profile-select-country-cd.is-invalid {
  padding: 11px 0px 10px 14px !important;
}
.form-select.sn-profile-select-country-cd.is-invalid:after {
  background: transparent !important;
}
/* .sn-country-libery-cutom {
  z-index: 999999999999999999999999999999 !important;
  position: relative;
  display: table;
  padding: 0;
} */
.custom-register-form-sec .is-invalid {
  border-color: #ccc;
  background-image: inherit !important;
}
.sn-country-libery-cutom .form-control:focus {
  border: 1px solid #ccc;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgb(140 145 150 / 0%);
}
.sn-country-libery-cutom  .form-control{
  width: 100%;
}
.sn-country-code-flag-de input {
  width: 100% !important;
}

.sn-country-code-flag-dess input {
  width: 100% !important;
}
.sn-include-file-number .rdt_Table {
  /* border: 1px solid #cccccc9c; */
}
.sn-include-number-tble .rdt_TableCell {
  /* border-right: 1px solid #ccccccab; */
}
.sn-include-number-tble .rdt_TableCol {
  border-left: 1px solid #ccccccab;
}
.sn-include-file-number .sn-file-input {
  /* width: 100px; */
}
.sn-include-btn-submit-num {
  /* margin-top: 29px; */
}
.sn-number-file--filter {
  display: flex;
  gap: 15px;
  justify-content: end;
}


.sn-country-code-flag-dess input {
  width: 100% !important;
}
.sn-storage-tble-top {
  padding: 10px 0px 10px 15px;
}
.sn-storage-tble-top input {
  display: none;
}
.sn-storage-tble-top .sn-employee-managment-tble-detail .bSqufN {
  height: 400px;
  min-height: 400px;
  max-height: 400px;
}
.sn-storage-managment-card-chart{
    /* min-width: 513px; */
    max-height: 513px;
    height: 513px;
}
.sn-storage-tble-top .sn-employee-managment-tble-detail {
  overflow: hidden !important;
  margin-top: 0;
}
.sn-storage-tble-top .rdt_Table {
  overflow: inherit;
}
button.button-reset-chart-time {
  position: absolute;
  right: 20px;
  z-index: 100;
  top: 10px;
  border: 2px solid #e5e7eb !important;
  background: #f7f7f7 !important;
  color: #4b5563 !important;
  padding: 5px 10px 5px 10px;
}
.sn-storage-tble-top .sc-feUZmu.jbBJkG {
  height: 400px;
}
.sn-add-import-lead-filter-button .sn-add-import-filter {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1050;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  min-width: 200px;
  max-width: 200px;
}
.sn-add-import-lead-filter-button {
  top:16px;
}
.sn-add-import-lead-filter-button button {
  padding: 4px 10px 4px 10px;
}

.step-arrow-nav .nav .nav-link.active {
  background-color:rgb(241 113 113) !important;
  color: #fff;
  box-shadow: none;
}
.step-arrow-nav .nav .nav-link.done {
  background-color: rgb(242 122 113 / 66%);
  color: #fff;
  border-right: 1px solid #ffffff3d;
}
.step-arrow-nav .nav .nav-link.done h6{
  color: #fff;
}
.sn-bulkimport-tabs-main .step-arrow-nav .nav .nav-link.active::before {
  border-left-color:rgb(241 113 113) ;
}
.sn-bulk-title-tabs {
  display: flex;
  align-items: center;
}
.sn-bulk-title-tabs h6 {
  margin: 0;
}
.sn-bulk-title-tabs span {
  font-size: 18px;
  margin-right: 10px;
}
.step-arrow-nav .nav .nav-link.active h6{
  color:#fff;
}
.sn-bulkimport-map-field-select .ant-select-show-arrow {
    width: 100%;
}
.sn-storage-month-tble-first .huFvJk {
  height: 120px;
  min-height: 100px;
  max-height: 150px;
}

.sn-storage-month-tble-first .rdt_Table {
  overflow: scroll;
  height: 185px;
  min-height: 120px;
  max-height: 400px;
}

/* Action bulk lead managment css */
.sn-bulk-managment-tabs-box-red {
  height: calc(100% - 20px);
  border-color: #e0e0e0 !important;
}
.sn-bulk-managment-tabs-box-red .card-header {
  border-color: #e0e0e0 !important;
  background: #f3f6f9;
}
.sn-bulk-tabs-body-section .sn-bulk-main-card-txt-sec {
  height: 100%;
  min-height: 800px;
  max-height: 100%;
}
.sn-bulkimport-tabs-main .sn-bulk-summary-table-main tr td:first-child {
  background: #f3f3f92e;
}
.sn-bulk-lead-box-list-main ul li {
  line-height: 35px;
  list-style-type: none;
  display: flex;
  /* align-items: center; */
}
.sn-bulk-lead-box-list-main ul li p {
  margin: 0;
}
.sn-bulk-lead-box-list-main ul li input {
  margin-top: 10px;
  margin-right: 8px;
}
.sn-bulk-lead-box-list-main ul {
  padding: 0;
}
.sn-lead-field-tble-colum {
  width: 50%;
}
.sn-lead-map-field-tble-top {
  width: 60%;
  margin: auto;
}
.sn-bulk-next-back-btn button:first-child {
  margin-right: 20px;
}
.sn-bulk-next-back-btn {
  /* margin: auto; */
  text-align: right;
  /* width: 58%; */
}
.sn-bulk-report-analysis-date.sn-dasboard-datepicker-btn-main .ant-picker-range {
  margin-top: 0;
}
.sn-report-lead-tabs .nav-success.nav-tabs-custom .nav-link.active {
  color: #f17171 !important;
  background: #f171710d;
}
.sn-report-lead-tabs .nav-success.nav-tabs-custom .nav-link.active::after {
  background: #f17171;
}
.sn-report-lead-tabs .nav-link:hover, .nav-link:focus {
  color: #F44336;
}
/* LEad managment tabs tble ui */
.sn-lead-report-manavgment-tble-ui .bSqufN {
  height: auto !important;
  min-height: auto !important;
  max-height: auto !important;
}

/* Lead Details */

.sn-lead-detail-top {
  overflow: hidden;
}
.sn-lead-detail-tab-top ul li a {
  /* color: #333; */
}
.sn-lead-detail-tab-top ul li a.active {
  color: #f17171 !important;
}
.sn-lead-detail-tab-top ul li a.active::after {
  background-color: #f17171 !important;
}

/* call history page tble */

.sn-call-history-tble-colum input#exampleInputrounded {
  display: none;
}
.snlead-filter-header {
  height: 40px;
  border-color: #ced4da;
}
.sn-bulk-import-download-icon {
  margin-left: 6px;
  position: relative;
  top: -2px;
}

/* lead */

/* Base style for the checkbox */
.custom-checkbox {
  width: 16px;
  height: 16px;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #f17171;
  border-color: #f17171;
}
.custom-checkbox:checked::before {
  content: '✔';
  display: block;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 16px
}
.custom-checkbox:not(:checked)::before {
  content: '';
}

/*  */

.checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  margin-right: 10px; 
}

.sn-edit-delete-button-add-important-lead {
  display: flex;
  gap: 10px;
  margin-left: 10px; 
}

.sn-edit-delete-button-add-important-lead button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sn-edit-delete-button-add-important-lead button:hover {
  background-color: #ddd; /* Light gray on hover */
}

/* date input placeholder */

.ant-picker-input input::placeholder {
  color: #333 !important;
}

.sn-storage-managment-card-chart {
  /* min-width: 513px; */
  max-height: 500px;
  height: 300px;
}

.sn-storage-managment-storage-tble-issue .sn-storage-month-tble-first  .rdt_Table {
  overflow: scroll;
  height: unset;
  min-height: 40px;
  max-height: 500px;
}
.sn-storage-managment-storage-tble-issue .table-scroll .kdgCYE {
  min-height: 40px;
}

/* Call Logs Report tble ui */

.sn-call-logs-report-tble-common-isuee  .sn-tble-call-archive-mainn {
  overflow: auto;
  }
  .sn-sales-ninja-tble-filter-sea {
    max-height: 200px;
    overflow-y: scroll;
}
.sn-table-edit-button-main {
  margin-left: 8px;
}


.h-40{
  height: 40px;
}
.sn-notcontaced-lead-tble-main #exampleInputrounded {
  display: none;
}
.sn-notcontaced-lead-tble-main .sn-tble-call-archive-mainn {
  margin-top: 0;
}
/* lead settings */

.sn-lead-status-setting-btn {
  position: absolute;
  right: 15px;
  z-index: 99999;
  top: 15px;
}
.sn-lead-changes-status {
  padding: 2px 9px 3px 9px;
  align-items: center;
  display: flex;
}
.sn-lead-changes-status span {
  margin-right: 3px;
}

.snprole-managment-tble-responsive .eMkDBs {
  max-height: 400px;
  min-height: 100px;
  height: unset;
  overflow: scroll;
}

.snprole-managment-tble-responsive .rdt_Table {
  overflow: scroll;
}
.snprole-managment-tble-responsive .sc-feUZmu.eguvnT {
  overflow: scroll;
}
.snprole-managment-tble-responsive  .iLVlUV {
  height: calc(100% - 0px);
  max-height: 432px;
}

/* storage managment tble css */


@media (min-width: 1281px) and (max-width: 1600px) {
  .sn-storage-managment-tble-main-data div:first-child {
      margin-left: 0 !important; 
      overflow: inherit !important;
      text-overflow: inherit !important;
    white-space: inherit !important;
  }
}
.sn-employees-wise-usage-main-tble .table-scroll {
  overflow: scroll !important;
  min-height: 100px;
  max-height: 400px;
}
/* bulk inport csv tabs css */

.sn-file-uloadf-csv-inputs {
  cursor: pointer;
  padding: 0px 20px;
  background-color:#b1b9c1;
  color: #333;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  display: inline-block;
  width: 60%;
  min-height: 60px;
  max-height: 60px;
  line-height: 60px;
}
.sn-csv-input-file-upload {
  /* opacity: 0; */
  position: absolute;
  height: 60px !important;
  line-height: 54px;
  left: 16px;
}
.sn-bulk-import-information-button{
  /* width: 60%; */
  /* margin: auto; */
  margin-top: 60px !important;
}
.sn-app-version-tble-data-main .csYewm {
  min-height: 60px;
  height: auto;
  max-height: 600px;
}
.sn-app-version-tble-data-main .rdt_TableBody {
  min-height: 60px;
  height: auto;
  max-height: 600px;
}

.file-upload-container {
  position: relative;
  width: 100%;
}

.file-upload-label {
  display: block;
  background-color: #f5f5f5;
  border: 2px dashed #ddd;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-label:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.file-upload-content {
  color: #666;
  font-size: 16px;
}

.browse-text {
  color: #000;
  text-decoration: underline;
  font-weight: 500;
}

.file-upload-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.selected-file {
  color: #333;
  word-break: break-all;
  padding: 0 20px;
}
.sn-table-notcontacted-lead .rdt_Table {
  overflow: scroll;
  min-height: 530px;
  height: 530px;
  max-height: 530px;
}
.disabled-link {
  opacity: 0.6;
  cursor: not-allowed;
}